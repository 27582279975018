import { createGtm } from '@gtm-support/vue-gtm';

export default (app, gtmCode) => {
  app.use(
    createGtm({
      id: gtmCode,
      debug: import.meta.env.MODE === 'development',
      defer: true,
      compatibility: true,
    }),
  );
};
