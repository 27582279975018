import types from './types';
import config from '../../config';

export default {
  [types.SET_APPLICATION_SETTINGS](
    state,
    {
      theme,
      palette,
      layout,
      font,
      GTMCode,
      isDescriptionBoxActive,
      isVaixActive,
      gameMobileHeader,
      liveCasinoGroupsActive,
      groupSubgroupIconsActive,
      errorTracker,
      isSwipeDisabled,
      demoModeDisabled,
    },
  ) {
    state.applicationSettings.theme = theme;
    state.applicationSettings.palette = palette;
    state.applicationSettings.layout = layout;
    state.applicationSettings.font = font;
    state.applicationSettings.GTMCode = GTMCode;
    state.applicationSettings.isVaixActive = !config.isLive && isVaixActive;
    state.applicationSettings.isDescriptionBoxActive = isDescriptionBoxActive;
    state.applicationSettings.gameMobileHeader = gameMobileHeader;
    state.applicationSettings.liveCasinoGroupsActive = liveCasinoGroupsActive;
    state.applicationSettings.groupSubgroupIconsActive = groupSubgroupIconsActive;
    state.applicationSettings.errorTracker = errorTracker;
    state.applicationSettings.isSwipeDisabled = isSwipeDisabled;
    state.applicationSettings.demoModeDisabled = demoModeDisabled;
  },
  [types.SET_BOOTSTRAP_FAILED](state) {
    state.hasBootstrapFailed = true;
  },
  [types.SET_CONFIG](state, payload) {
    state.iOSversion.iOS12 = payload.isIOS12;
    state.isMobile.device = payload.isMobile.device;
    state.isLive = payload.isLive;
    state.isNativeApp = payload.isNativeApp;
    state.channelType = payload.integration.application;
  },
  [types.SET_LANGUAGE](state, payload) {
    state.language = payload;
  },
  [types.SET_TENANT](state, payload) {
    const { currency, tenant } = payload;

    state.tenant = {
      name: tenant.name,
      uuid: tenant.uuid,
      currency: currency.symbol,
      virtualCurrency: currency.virtualSymbol,
      country: tenant.country,
    };
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = payload;
  },
  [types.SET_VISUAL_VIEWPORT_HEIGHT](state, payload) {
    state.visualViewport.height = payload.height;
  },
  [types.SET_VISUAL_VIEWPORT_SCROLL](state, payload) {
    state.visualViewport.scrollTop = payload.scrollTop;
  },
  [types.SET_SCROLLABILITY](state, payload) {
    state.isScrollable = payload;
  },
  [types.SET_GAMIFICATION](state, payload) {
    state.gamification = payload;
  },
  [types.SET_REFERRER_URL](state, payload) {
    state.referrerUrl = payload;
  },

  [types.SET_MARKETING_SLOTS](state, payload) {
    state.marketingSlots = payload;
  },

  [types.SET_INITIAL_NAVIGATION_DATA](state, payload) {
    state.initialRoute.name = payload.name;
    state.initialRoute.params = payload.params;
  },

  [types.SET_INITIAL_NAVIGATION_FINISHED](state) {
    state.initialRoute.navigationToInitialRouteFinished = true;
  },

  [types.SET_IS_FILTER_SEARCH_KEYBOARD_VISIBLE](state, payload) {
    state.isFilterSearchKeyboardVisible = payload;
  },

  [types.SET_IS_FILTER_SEARCH_EXPANDED](state, payload) {
    state.isFilterSearchExpanded = payload;
  },

  [types.SET_FILTER_SEARCH_TERM](state, payload) {
    state.filterSearchTerm = payload;
  },

  [types.SHOW_NOTIFICATION](state, { text, variant = 'negative' }) {
    const NOTIFICATION_DELAY = 5000;

    if (state.notification.isVisible) clearTimeout(state.notification.timeout);

    state.notification.variant = variant;
    state.notification.text = text;
    state.notification.isVisible = true;

    state.notification.timeout = setTimeout(() => {
      state.notification.isVisible = false;
    }, NOTIFICATION_DELAY);
  },
  [types.HIDE_NOTIFICATION](state) {
    state.notification.isVisible = false;
    clearTimeout(state.notification.timeout);
  },
};
