import * as Sentry from '@sentry/vue';

const MESSAGE_SEVERITY_LEVEL = {
  debug: 'debug',
  info: 'info',
  log: 'log',
  warning: 'warning',
  error: 'error',
  fatal: 'fatal',
};
const trackMessageWithContext = (
  message,
  severityLevel = MESSAGE_SEVERITY_LEVEL.log,
  user = {
    id: null,
    nickname: null,
  },
) => {
  Sentry.withScope((scope) => {
    scope.setUser({
      id: user?.id,
      username: user?.nickname,
    });
    scope.setLevel(severityLevel);
    Sentry.captureMessage(message);
  });
};

const initializeErrorTracker = (app, router) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    normalizeDepth: 6,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        tracePropagationTargets: [import.meta.env.VITE_APP_APP_URL, /^\//],
      }),
    ],
    ignoreErrors: [
      /Loading chunk .+ failed/i,
      /Loading CSS chunk .+ failed/i,
      /Cannot read properties of null \(reading 'title'\)/i,
      /Unexpected token '<'/i,
      /Unable to preload CSS/i,
      /Importing a module script failed/i,
      /Failed to fetch dynamically imported module/i,
      /Load failed/i,
      /'text\/html' is not a valid JavaScript MIME type/i,
    ],
    tracesSampleRate: 0.001,
  });
};
export { MESSAGE_SEVERITY_LEVEL, trackMessageWithContext };
export default initializeErrorTracker;
