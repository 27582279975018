import { capitalize, omit } from 'lodash-es';
import { eventBus, logger, prettyPrintJson } from '@/utility';
import constants from '@/utility/constants';

let instance = null;
let initializationResolve = null;

export default {
  _getStandardizedMessagePayload(message, payload) {
    if (message === 'Slave.Load') {
      // Handle missing user.logged flag
      return {
        ...payload,
        user: {
          ...payload.user,
          logged: !!payload.user?.uuid,
          profile: {
            token: payload.user?.profileToken,
          },
        },
        token: payload.user?.auth?.token,
      };
    } else if (message === 'User.AuthorizationChanged') {
      // Handle missing user.logged flag and normalize object to match GG integrator
      const auth = omit(
        {
          ...payload,
          user: {
            ...payload.user,
            logged: !!payload.user?.uuid,
            profile: {
              token: payload.user?.profileToken,
            },
          },
          token: payload?.user?.auth?.token,
        },
        ['postLogin'],
      );

      return {
        auth: auth,
        postLogin: payload.postLogin,
      };
    }

    return payload;
  },
  init() {
    this.setInstance();

    this.setListener();

    return new Promise((resolve) => {
      initializationResolve = resolve;

      // Notify basic setup is done
      logger.info('Sending Slave.Init');
      instance.sendMessage({
        action: 'Slave.Init',
      });
    });
  },

  setInstance() {
    instance = {
      sendMessage: (data) => {
        const message = typeof data !== 'string' ? JSON.stringify(data) : data;

        try {
          window.webkit.messageHandlers.WebAppListener.postMessage(message);
        } catch (err) {
          logger.error(`Could not reach native wkwebview code and send message: ${message}`);
        }
      },
    };
  },

  notifyAppLoad() {
    logger.info('Sending Slave.Loaded');
    instance.sendMessage({
      action: 'Slave.Loaded',
    });
  },

  notifyGameEvent(event, game) {
    logger.info(`Notify game event: ${event}, ${game.url}`);
    instance.sendMessage({
      action: `Game.${capitalize(event)}`,
      data: {
        game: {
          id: game.id,
          productDisplayId: game.productDisplayId,
          code: game.code,
          demo: game.isDemoMode,
          title: game.title,
          providerName: game.provider?.name,
          url: game.url,
          position: game.position,
          isVaixEvent: game.isVaixEvent,
          swimLane: game.swimLane,
          page: constants.VAIX.HOMEPAGE,
          isLive: game.isLive,
        },
      },
    });
  },

  notifyGameImpression({ productName, gameIds, vaixSwimLane }) {
    logger.info(`Notify Analytics game impression event: ${productName}`);
    instance.sendMessage({
      action: 'Analytics.Send',
      data: {
        type: 'VaixImpressions',
        params: {
          category: productName,
        },
        content: [
          {
            id: vaixSwimLane,
            value: {
              gameIds,
              page: constants.VAIX.HOMEPAGE,
            },
          },
        ],
      },
    });
  },

  notifyRouteChange() {},

  notifyChangeTitle() {},

  notifyGroupsClickEvent(data) {
    logger.info('Sending Analytics.Send for groups/subgroups click');
    instance.sendMessage({
      action: 'Analytics.Send',
      data,
    });
  },

  requireUserLogin(postLoginPayload) {
    logger.info('Sending User.LoginRequired');
    instance.sendMessage({
      action: 'User.LoginRequired',
      ...(postLoginPayload && {
        data: {
          postLogin: postLoginPayload,
        },
      }),
    });
  },

  requestGameOpening(game) {
    this.notifyGameEvent('open', game);
  },

  requestNavigationChange(url) {
    logger.info(`Sending Navigation.GoTo: ${url}`);
    instance.sendMessage({
      action: 'Navigation.GoTo',
      data: {
        url,
      },
    });
  },

  notifyJackpotWin(data) {
    logger.info('Sending JackpotWidget.Win');
    instance.sendMessage({
      action: 'JackpotWidget.Win',
      data,
    });
  },

  setListener() {
    window.onParentMessage = (message, payload) => {
      const isLoadMessage = message === 'Slave.Load';
      const data = this._getStandardizedMessagePayload(message, payload?.data);

      logger.info(
        `Incoming message from native integrator: ${message}, ${prettyPrintJson(payload)}`,
      );

      if (isLoadMessage) {
        initializationResolve(data);
      } else {
        eventBus.publish(message, data);
      }
    };
  },
};
