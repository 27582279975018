/* eslint-disable no-console */
export default {
  error: (msg) => {
    if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'test') return;
    console.error(`7Casino ERROR: ${msg}`);
  },
  info: (msg) => {
    if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'test') return;
    console.log(`7Casino INFO: ${msg}`);
  },
  debug: (...args) => {
    console.log('7Casino DEBUG', args);
  },
};
