import GatewaySlave from '@nsftx/seven-gravity-gateway/slave';
import { capitalize } from 'lodash-es';
import { eventBus } from '@/utility';
import constants from '@/utility/constants';
import appConfig from '../../../../../config';

let instance = null;

export default {
  /**
   * Initialize gateway.
   *
   * @param {Object} config.slaveId - Unique identifier of a frame
   * @param {Object} [config.data] - Data which will be passed to master on init phase
   * @param {Array} [config.allowedOrigins] - Array of allowed origins
   * @param {Boolean} [config.debug] - Debug setting
   * @param {String | Worker} [config.worker] - Web worker configuration
   * @param {Object} [config.eventPropagation] - Events which will be propagated to master frame
   * @param {Object} [config.eventListeners] - Events which are required from master frame
   *
   * @returns {Promise.<{*}>} - Returns master data if any
   */
  init(config) {
    return new Promise((resolve) => {
      instance = GatewaySlave({
        load: (message) => {
          resolve(message.data);
        },
        ...config,
      });

      this.setListeners();
    });
  },

  get api() {
    return instance;
  },

  notifyAppLoad() {
    this.sendMessage({
      action: 'Slave.Loaded',
    });
  },

  notifyGameEvent(event, game) {
    this.sendMessage({
      action: 'Slave.Event',
      event: `Game.${capitalize(event)}`,
      data: {
        game: {
          id: game.id,
          productDisplayId: game.productDisplayId,
          code: game.code,
          demo: game.isDemoMode,
          title: game.title,
          providerName: game.provider?.name,
          position: game.position,
          isVaixEvent: game.isVaixEvent,
          swimLane: game.swimLane,
          page: constants.VAIX.HOMEPAGE,
          isLive: game.isLive,
        },
      },
    });

    if (event === 'open') {
      this.notifyChangeMeta(game);
    }

    if (appConfig.isMobile.device) {
      this.notifyUIAction(event);
    }
  },

  notifyGameClickEvent() {
    this.sendMessage({
      action: 'Slave.Event',
      event: 'click',
    });
  },

  notifyUIAction(event) {
    if (event !== 'open' && event !== 'close') return;

    this.sendMessage({
      action: event === 'open' ? 'UI.Hide' : 'UI.Show',
      data: {
        name: ['All'],
      },
    });
  },

  notifyChangeMeta(game) {
    this.sendMessage({
      action: 'Document.ChangeMeta',
      data: {
        name: 'description',
        content: game.description,
      },
    });
  },

  notifyChangeTitle(title) {
    this.sendMessage({
      action: 'Document.ChangeTitle',
      data: {
        titleBreadcrumbTrail: title,
      },
    });
  },

  notifyGameImpression({ productName, gameIds, vaixSwimLane }) {
    this.sendMessage({
      action: 'Analytics.Send',
      data: {
        type: 'VaixImpressions',
        params: {
          category: productName,
        },
        content: [
          {
            id: vaixSwimLane,
            value: {
              gameIds,
              page: constants.VAIX.HOMEPAGE,
            },
          },
        ],
      },
    });
  },

  notifyRouteChange(data) {
    this.sendMessage({
      action: 'Router.RouteChanged',
      data,
    });
  },

  notifyGroupsClickEvent(data) {
    this.sendMessage({
      action: 'Analytics.Send',
      data,
    });
  },

  requestGameOpening(game) {
    this.requestNavigationChange(game.url);
  },

  requestNavigationChange(url) {
    this.sendMessage({
      action: 'Router.GoTo',
      data: {
        url,
      },
    });
  },

  requireUserLogin(postLoginPayload) {
    this.sendMessage({
      action: 'User.LoginRequired',
      ...(postLoginPayload && {
        data: {
          postLogin: postLoginPayload,
        },
      }),
    });
  },

  openZiqniWidget() {
    this.sendMessage({
      action: 'UI.Show',
      data: {
        name: 'ZiqniWidget',
      },
    });
  },

  openFastDeposit() {
    this.sendMessage({
      action: 'UI.Show',
      data: {
        name: 'FastDeposit',
      },
    });
  },

  notifyJackpotWin(data) {
    this.sendMessage({
      action: 'Analytics.Send',
      data: {
        type: 'CasinoJackpot',
        params: {
          category: data.type,
          content: data.params.content,
        },
      },
    });
  },

  sendMessage(message) {
    if (!this.api) return;

    this.api.sendMessage(message);
  },

  setListeners() {
    this.subscribe('User.AuthorizationChanged', (message) => {
      eventBus.publish('User.AuthorizationChanged', message.data);
    });

    this.subscribe('User.BalanceChanged', (message) => {
      eventBus.publish('User.BalanceChanged', message.data);
    });

    this.subscribe('Router.RouteChanged', (message) => {
      eventBus.publish('Router.RouteChanged', message.data);
    });

    this.subscribe('Master.Event', (message) => {
      const SCROLL_EVENT_NAME = 'scroll';

      if (message.event === SCROLL_EVENT_NAME) {
        eventBus.publish('VisualViewportScroll', {
          scrollTop: message.top,
        });
      }
    });

    this.subscribe('Widget.SettingsChanged', (message) => {
      eventBus.publish('Widget.SettingsChanged', message.data);
    });

    this.subscribe('Game.Close', () => {
      eventBus.publish('Game.Close');
    });
  },

  subscribe(event, callback) {
    if (!this.api) return;

    this.api.subscribe(event, callback);
  },
};
