const setupSevenComponents = async () => {
  document.documentElement.dataset.scChannel = 'terminal';
  document.documentElement.dataset.scTheme = 'dark';
  document.documentElement.dataset.scPalette = 'default';

  await Promise.all([
    import('@nsftx/seven-components/style'),
    import('@nsftx/seven-components/styles/components/terminal'),
    import('@nsftx/seven-components/palettes/default'),
  ]);
};

export default {
  async install() {
    await setupSevenComponents();
  },
};
