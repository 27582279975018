<template>
  <AppModal @close="focusModalContent">
    <div
      ref="notificationModal"
      v-top-position="{
        isScrollable,
        visualViewportHeight: visualViewport.height,
        visualViewportScrollTop: visualViewport.scrollTop,
      }"
      role="alert"
      tabindex="0"
      :class="{
        focus: isClickedOutside,
        'non-scrollable': !isScrollable,
      }"
      class="modal-content notification-modal"
      @click.stop=""
      @keyup.esc="focusModalContent"
    >
      <div class="notification-modal-content">
        <div class="notification-modal-description">
          {{ notification.message }}
        </div>
      </div>

      <div class="notification-modal-actions">
        <AppButton
          v-if="reject"
          class="notification-modal-btn"
          @click="$emit('reject', reject)"
        >
          {{ reject }}
        </AppButton>
        <AppButton
          v-if="confirm"
          class="notification-modal-btn"
          primary
          @click="$emit('confirm', confirm)"
        >
          {{ confirm }}
        </AppButton>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { mapGetters } from 'vuex';
import AppModal from '@/components/common/AppModal.vue';
import AppButton from '@/components/common/AppButton.vue';

export default {
  name: 'AppNotificationModal',
  components: {
    AppModal,
    AppButton,
  },
  props: {
    confirm: {
      type: String,
      required: true,
    },
    reject: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'reject'],
  data() {
    return {
      isClickedOutside: false,
    };
  },
  computed: {
    ...mapGetters({
      notification: 'getModalNotification',
      visualViewport: 'getVisualViewport',
      isScrollable: 'isScrollable',
    }),
  },
  mounted() {
    this.$refs.notificationModal.focus();
  },
  methods: {
    focusModalContent() {
      this.isClickedOutside = true;
      setTimeout(() => {
        this.isClickedOutside = false;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/styles/components/_modalContent.scss';

.notification-modal {
  max-width: 34.4rem;
  transition: transform 100ms $ease-out-quint;
  backface-visibility: hidden;

  &.focus {
    transform: scale(1.015);
  }

  &.non-scrollable {
    position: absolute;
  }

  @include breakpoint-small {
    max-width: 48rem;
  }
}

.notification-modal {
  &-content {
    padding: 0 2.4rem 0 2.4rem;
  }

  &-description {
    --fs-400: 1.6rem;
    font-size: var(--fs-400);
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
  }

  &-title {
    font-size: var(--fs-500);
  }

  &-actions {
    font-size: var(--fs-300);
    color: var(--accent);
    padding: 1.6rem 0.8rem;
    display: flex;
    margin-left: auto;
  }

  &-btn {
    padding: var(--btn-padding);
    text-transform: uppercase;
  }
}
</style>
